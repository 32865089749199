import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { EuiSpacer } from '@elastic/eui';
import PrimaryBoxUpdates from 'components/Advanced_User/IOBroker_on_Raspberry_Pi/PrimaryBox';
import PrimaryBox from 'components/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Blockly_Script_Engine/PrimaryBox';
import NavButtons from 'components/Advanced_User/NavButtons';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "ioBroker Blockly Script Engine",
  "path": "/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Blockly_Script_Engine/",
  "dateChanged": "2018-09-20",
  "author": "Mike Polinowski",
  "excerpt": "ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD",
  "image": "../../AU_SearchThumb_ioBroker.png",
  "social": "/images/Search/AU_SearchThumb_ioBroker.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_Advanced_ioBroker_white.webp",
  "chapter": "Advanced User",
  "category": "smarthome",
  "type": "ioBroker"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">




    <SEOHelmet title='ioBroker Blockly Script Engine' dateChanged='2018-09-20' author='Mike Polinowski' tag='INSTAR IP Camera' description='ioBroker is an integration platform for the Internet of Things, focused on Building Automation, Smart Metering, Ambient Assisted Living, Process Automation, Visualization and Data Logging. Control your INSTAR IP camera with your Raspberry Pi. Compatible with the camera models IN-6001 HD, IN-6012 HD, IN-6014 HD, IN-5905 HD, IN-5907 HD, IN-7011 HD, IN-8003 Full HD, IN-8015 Full HD, IN-9008 Full HD, IN-9020 Full HD' image='/images/Search/AU_SearchThumb_ioBroker.png' twitter='/images/Search/AU_SearchThumb_ioBroker.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Erweitert/IOBroker_auf_Raspberry_Pi/IOBroker_Setup/Blockly_Script_Engine/' locationFR='/fr/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Blockly_Script_Engine/' crumbLabel="ioBroker" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "iobroker-blockly-script-engine",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#iobroker-blockly-script-engine",
        "aria-label": "iobroker blockly script engine permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`ioBroker Blockly Script Engine`}</h1>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBoxUpdates mdxType="PrimaryBoxUpdates" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`ioBroker comes with a `}<a parentName="p" {...{
        "href": "https://developers.google.com/blockly/"
      }}>{`Blockly Adapter`}</a>{` that - very similar to Node-RED - allows you to create small scripts with a graphical user interface. You can install it from the `}<strong parentName="p">{`Adapters`}</strong>{` tab:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/95399ead9a981c7bbb22366f6db1a036/e9beb/Blockly_01.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.347826086956523%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABPElEQVQY0wExAc7+AG+Hmo+gsE1vjlyj4Wu19GOw8Gyy7om226bF3qDC3p3A3pnB4mCw8las81it8lis8WCu8WOw8luq7VCe4gCKnq6tucRZfZx5sOB3t+92tex7tuyMuN+aweWxzOTG2OW/zt6fuNudud+eud6bv+WdyOyfyu6bxuuWwOQAFy9DL05rMVNzw9Hc1OX31+Tz1uTyzuDxudHm2uTt9fPx8urp6tHM9drV9NnU++Tf+4SC/ejj//34/vjyAIuRlZihqZafp9jQzuLW0eno5/Px7u7s6dnW0+Df3tzc3eTl5ubq6+Ll5+Hm5+Hi4ulvdODM0Ozy8+zs7QCt1vmm0/qs2v/e4d3e2dDe4OLr6+vn6OjV1tjf3+Dh4eDl5eXq6urm5eXm5ebp6eno5ufm5+js7Ozs7OzAmukVfx3CoQAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95399ead9a981c7bbb22366f6db1a036/e4706/Blockly_01.avif 230w", "/en/static/95399ead9a981c7bbb22366f6db1a036/d1af7/Blockly_01.avif 460w", "/en/static/95399ead9a981c7bbb22366f6db1a036/b6582/Blockly_01.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/95399ead9a981c7bbb22366f6db1a036/a0b58/Blockly_01.webp 230w", "/en/static/95399ead9a981c7bbb22366f6db1a036/bc10c/Blockly_01.webp 460w", "/en/static/95399ead9a981c7bbb22366f6db1a036/87ca7/Blockly_01.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/95399ead9a981c7bbb22366f6db1a036/81c8e/Blockly_01.png 230w", "/en/static/95399ead9a981c7bbb22366f6db1a036/08a84/Blockly_01.png 460w", "/en/static/95399ead9a981c7bbb22366f6db1a036/e9beb/Blockly_01.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/95399ead9a981c7bbb22366f6db1a036/e9beb/Blockly_01.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/dc43b38641154fd9cc433bef11194ef7/e9beb/Blockly_02.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "48.26086956521739%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAYAAAC0VX7mAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACBklEQVQoz32QX2vTUBjG8yH8EqKfwWvFbQjCQG+mToUNFYYX4p333igKXritc1KFCWqp26pzG4453Rzeat3SJW3Nv9Okpkld2qT7SZK2MhAP/HjOed73PLy80p1Hczx5tcyZmw8YyapMvG0ylneZmK9zv/Cd/FaJ15sl8l9kXm4p3Jj/xWjO5WrO5UrO5fq8y8Rig7Gcw7lZBenW3Ukm5wqcvf2Ykdk9hrM2w08F57OCS9kq115ojM9pjD7TuJDVGMpYnJ62GMxYDGQEgxnB0Izg1JTFxec60pFjJzh68jLHB8ZZ/KqxrcMnpc0HuU2hGLIsh6wpEe92UlbkkFU5pFCM6y1Wdlusym1W5IgN2UfKvVng/doGS6vrOKJC6KuEwW/2/UZC0GwQ7Xt0Ag9aHrRTPQg8gqZHpeanXtsn8OtIP3QPw+sQALvyDh/Xl7AdF9OysIRIMK2/GF2EEJR1weeiwOp6lqghTW/7PNxscm/DQ/4pMA0TXdcxDAPbtv+LY9s06oc9yaw5RFFEJ+okRhxkWRa1Wq1PPE2Pw35KrydWyam7HJCe2DBNMwlMtHvvNff9f2g/sN4KaeYXCKZm0E0TRVFQVZVyWU20VColHxzHSaZX9vYSP6WMqqhUq9Wknk7oNXG/FYl2ZHTD6IaVk8BY47DefuLA2KtUKv163B+/NU1LdvgHl83BCwLTl+YAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc43b38641154fd9cc433bef11194ef7/e4706/Blockly_02.avif 230w", "/en/static/dc43b38641154fd9cc433bef11194ef7/d1af7/Blockly_02.avif 460w", "/en/static/dc43b38641154fd9cc433bef11194ef7/b6582/Blockly_02.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/dc43b38641154fd9cc433bef11194ef7/a0b58/Blockly_02.webp 230w", "/en/static/dc43b38641154fd9cc433bef11194ef7/bc10c/Blockly_02.webp 460w", "/en/static/dc43b38641154fd9cc433bef11194ef7/87ca7/Blockly_02.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/dc43b38641154fd9cc433bef11194ef7/81c8e/Blockly_02.png 230w", "/en/static/dc43b38641154fd9cc433bef11194ef7/08a84/Blockly_02.png 460w", "/en/static/dc43b38641154fd9cc433bef11194ef7/e9beb/Blockly_02.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/dc43b38641154fd9cc433bef11194ef7/e9beb/Blockly_02.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the Scripts tab to load the Blockly interface:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/657474e6679f3ff4ed4079c7e434a767/e9beb/Blockly_03.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "24.782608695652172%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAFCAIAAADKYVtkAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA+0lEQVQY02Mw9IievW5vec98r/q1KVu+J6x9n7zxU+KaN/mbXhdsflOw+U3h5tdJ699HrvkYteZj5JqP8es+pmz8FLfuQ9CCxww6DoGLNuypmrQyvX/T9uufNl95v/nK+w3nX204+3TLxVdbwWjD+ddrzr9ZC0brL7zZcPHNuvOvV595wcAhqqjjFqPsnFjZO///708/Pn/48fn93x9f3r189uXD268fQejbx7ffP0HRt49Q9P3zO4a5i5Zv3HVg/fa9R09dePLo2q07d169fvP6zduHjx69fvMWP2L48u3Hz5+///////v379evX7958+YtGDx+/PgtIQAAJoTwGshsCpsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/657474e6679f3ff4ed4079c7e434a767/e4706/Blockly_03.avif 230w", "/en/static/657474e6679f3ff4ed4079c7e434a767/d1af7/Blockly_03.avif 460w", "/en/static/657474e6679f3ff4ed4079c7e434a767/b6582/Blockly_03.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/657474e6679f3ff4ed4079c7e434a767/a0b58/Blockly_03.webp 230w", "/en/static/657474e6679f3ff4ed4079c7e434a767/bc10c/Blockly_03.webp 460w", "/en/static/657474e6679f3ff4ed4079c7e434a767/87ca7/Blockly_03.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/657474e6679f3ff4ed4079c7e434a767/81c8e/Blockly_03.png 230w", "/en/static/657474e6679f3ff4ed4079c7e434a767/08a84/Blockly_03.png 460w", "/en/static/657474e6679f3ff4ed4079c7e434a767/e9beb/Blockly_03.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/657474e6679f3ff4ed4079c7e434a767/e9beb/Blockly_03.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the `}<strong parentName="p">{`New Script`}</strong>{` icon:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/c62b92a586d3d74f1f9b3c0facec771e/e9beb/Blockly_04.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "44.34782608695652%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAJCAIAAAC9o5sfAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABNklEQVQoz5XNzUoCURjG8bmObqJF3UB3EBUUtYmQooUgBkHUpnXLamGRYFEkmJWafQ1UWJtoUeZAaGQL58zM6bznY4aZRGc6IUK4knz5rR748yoLa/G9zO3Y4sbK0uFs1p1O89AxnzvC0RyJZEk0S8IZmEyJtqmUmDmxQ6fOxIE1uqsr4dX1zf3c+PL2XSxZqAj1jV9pcP5iXBSRqmFVw9cazr+SP5ellnzRyj1bSl//0ODw/MBIpPhRlU3HE6zh2Z5NwUKeTV0BrgCvQ8dClWQ6k795OFPvP6vl90qphkwCFBlmTUcE6BeBLhThuL7vSymFEBhjAKCUmqaJEKKUQtdTagZ2ve8gCBjj7amHGJiQUv5IyTknhPQWGyap1xt+02eM9RzvJB5jiaf4VkEvV5loPf9//AsBwtBzbA+FlAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c62b92a586d3d74f1f9b3c0facec771e/e4706/Blockly_04.avif 230w", "/en/static/c62b92a586d3d74f1f9b3c0facec771e/d1af7/Blockly_04.avif 460w", "/en/static/c62b92a586d3d74f1f9b3c0facec771e/b6582/Blockly_04.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/c62b92a586d3d74f1f9b3c0facec771e/a0b58/Blockly_04.webp 230w", "/en/static/c62b92a586d3d74f1f9b3c0facec771e/bc10c/Blockly_04.webp 460w", "/en/static/c62b92a586d3d74f1f9b3c0facec771e/87ca7/Blockly_04.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/c62b92a586d3d74f1f9b3c0facec771e/81c8e/Blockly_04.png 230w", "/en/static/c62b92a586d3d74f1f9b3c0facec771e/08a84/Blockly_04.png 460w", "/en/static/c62b92a586d3d74f1f9b3c0facec771e/e9beb/Blockly_04.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/c62b92a586d3d74f1f9b3c0facec771e/e9beb/Blockly_04.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`And choose the Blockly Script Engine to load the Blockly interface:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ea6edce1372ce25c350965c6c449b246/e9beb/Blockly_05.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "40.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAICAIAAAB2/0i6AAAACXBIWXMAAA7EAAAOxAGVKw4bAAABhklEQVQY012QvUvDQBTA+w86OOjo4Ki4OLm4aHPRgh+gdKiD4CCoiCUxiq1Cqdpc7mJreyWWmKZaJdo2rTTpFZtgIjlBwR8P3nvDj/cRi+foSt5duBwkb7tFFWGMCSHValVjPERoLFjFMikXkYIQQrG3tm1/9D3fp5RijPP5fCqVSiaTPM9zHAcA4ECCA6sAAB6AtQQfj8d39gWMcST3HWc0GoVhOBxShBCEMJvNZjIZSZLECOlc2DkT90RRSgvi+u7JwbFwdaNijBUFxcIwDIKAyUNFUUqlEh0MrLfXp2fD+6T+V9hrrL43DoOvsGnTmSPLtEdOr638rB0wfmVVVV3H2djempmfN03Tsqy52emlpUXf869vCmOTU2nhtNu1IYSR/G9yUVU/PO9uGVyNT3Scvt2xc7lrhLDrui/N5mXmwjCMVqulsNF/MqXRzRihiq43Do6am1tE16uE1OvG46NOCNE0zTTNWq1WLpcR478c/UyW5ftSgVTkQgHCqJNlGTJ+ix/5G3bhga0JuqcAAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ea6edce1372ce25c350965c6c449b246/e4706/Blockly_05.avif 230w", "/en/static/ea6edce1372ce25c350965c6c449b246/d1af7/Blockly_05.avif 460w", "/en/static/ea6edce1372ce25c350965c6c449b246/b6582/Blockly_05.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ea6edce1372ce25c350965c6c449b246/a0b58/Blockly_05.webp 230w", "/en/static/ea6edce1372ce25c350965c6c449b246/bc10c/Blockly_05.webp 460w", "/en/static/ea6edce1372ce25c350965c6c449b246/87ca7/Blockly_05.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ea6edce1372ce25c350965c6c449b246/81c8e/Blockly_05.png 230w", "/en/static/ea6edce1372ce25c350965c6c449b246/08a84/Blockly_05.png 460w", "/en/static/ea6edce1372ce25c350965c6c449b246/e9beb/Blockly_05.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ea6edce1372ce25c350965c6c449b246/e9beb/Blockly_05.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Drag a Trigger - Event: if object block onto the canvas:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/0652d2437ca82ca515843c3bca61e427/e9beb/Blockly_06.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "37.391304347826086%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAHCAIAAACHqfpvAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABS0lEQVQY033NzUvCYACAcf/aLl47FHTMolMfGISnIOhgVs4CgxSDcs6ROjXUrTnfbe/2zr2ffiwPgoukU0XP/ceTGBNOJrMAUxpiPo9GiupW5aDRwFob1hW/8Trv9SfdN4pDz/MM3ej1eq7rCi4YY4mBDRqmozuhB1zEZ8YQeADQ8ThwINRHcGD6NiSEBSHGmEyn08ViEUUR5zyKPhIBsiF0KKWYEMZYS9VeqnLfNOTbSnb7NLd5WDzL9QFsdvue78dxvFqt4nXL5TJBKWOMUUoJIVyIdrVVeyx16kotWyxnrp7O86pU8qEDRhZC6DcmdB3BRMxmbalY3j15TqW1a0m+vFFzkuU4AGPLtv/E332dudA0Va7V9KGpFu4vkgeFvQzaT403kqClIYz/w0IIpdlUFOXdsl7z+Uo63Xy484+O0dbOqNNFYfgDfwLk6GyTV1oM5wAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0652d2437ca82ca515843c3bca61e427/e4706/Blockly_06.avif 230w", "/en/static/0652d2437ca82ca515843c3bca61e427/d1af7/Blockly_06.avif 460w", "/en/static/0652d2437ca82ca515843c3bca61e427/b6582/Blockly_06.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/0652d2437ca82ca515843c3bca61e427/a0b58/Blockly_06.webp 230w", "/en/static/0652d2437ca82ca515843c3bca61e427/bc10c/Blockly_06.webp 460w", "/en/static/0652d2437ca82ca515843c3bca61e427/87ca7/Blockly_06.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/0652d2437ca82ca515843c3bca61e427/81c8e/Blockly_06.png 230w", "/en/static/0652d2437ca82ca515843c3bca61e427/08a84/Blockly_06.png 460w", "/en/static/0652d2437ca82ca515843c3bca61e427/e9beb/Blockly_06.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/0652d2437ca82ca515843c3bca61e427/e9beb/Blockly_06.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Click on the Object ID to assign a trigger to the object:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/7cdd7cf3373ca964a681d5d15fe9f804/e9beb/Blockly_07.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "65.21739130434783%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAANCAIAAAAmMtkJAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABmElEQVQoz53RTU/bMBgH8HxLDvsEHLjvuG+wAxK7IFFA2o0DRyRG2yVN06bOBqRqCknapolb0ziO67z0BceoVKCKbiDxPzySn8c/2dIj2bbtDQa9Xo+Q2PO8Qb/vui5j0zRNCSEYYz/wHdd1HMcAoGNZvu8TQiilYRhKU0oJIVmWERJFMY2nDEcRnkwopWmaJklCSByGIYQQIRRFERqPMcaMsSRJJOvecRw7CAKEEMMohsMHNGZ5Pn9JnudZluV5vlwuOeeLxYIxtu5I1YtKpVI2zTb03Prh8fm3feXLLikdCSEKzouiEBvZPM7nc0mrNpp6Exh/h727q4PTk6/7Fzt7YelYCMEf+SvYrissX6qy8hsYf/p2V/7+Qyv91M/OsTdY4eeX38NNVVHr9Xa7Df2h3yjHDqC2vpxOni99hEGj1moB0zQhhOuv8kf+yj7Av6plra5ZlhUEwXqwnf/ick27ub7udDqfwXJVBobR7XY/g4Gias3G7e3NaDQSW3mz583mCuuKXlMVwzDWmP8rRVG8qUKI2Wz2BOTw1vIbWMtmAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7cdd7cf3373ca964a681d5d15fe9f804/e4706/Blockly_07.avif 230w", "/en/static/7cdd7cf3373ca964a681d5d15fe9f804/d1af7/Blockly_07.avif 460w", "/en/static/7cdd7cf3373ca964a681d5d15fe9f804/b6582/Blockly_07.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/7cdd7cf3373ca964a681d5d15fe9f804/a0b58/Blockly_07.webp 230w", "/en/static/7cdd7cf3373ca964a681d5d15fe9f804/bc10c/Blockly_07.webp 460w", "/en/static/7cdd7cf3373ca964a681d5d15fe9f804/87ca7/Blockly_07.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/7cdd7cf3373ca964a681d5d15fe9f804/81c8e/Blockly_07.png 230w", "/en/static/7cdd7cf3373ca964a681d5d15fe9f804/08a84/Blockly_07.png 460w", "/en/static/7cdd7cf3373ca964a681d5d15fe9f804/e9beb/Blockly_07.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/7cdd7cf3373ca964a681d5d15fe9f804/e9beb/Blockly_07.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`We are using the MQTT adapter to send messages to our IP camera. And `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/MQTT_Client/"
      }}>{`we already learnt`}</a>{` that, if a message arrives, we will see it inside the ioBroker `}<strong parentName="p">{`Objects`}</strong>{` tab. For testing we can use the program `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/MQTT_Client/"
      }}>{`MQTT.fx`}</a>{` to send messages via MQTT, receive them in ioBroker and use them as a trigger in our Blockly script.`}</p>
    <p>{`We already connected MQTT.fx to our MQTT server and am now publishing a message `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`iobroker/alarmserver/triggered`}</code>{`. Once we click `}<strong parentName="p">{`Publish`}</strong>{` we see the corresponding entry in the `}<strong parentName="p">{`ioBroker Objects List`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/cc809a28444053c843f3c23842104294/e9beb/Blockly_10.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "28.260869565217394%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABQUlEQVQY02OYd+PyymP7FqxYuGD14gWrFi1Ys2TesvlLt61b/eT20ruX5l87u+DG+RUPrq96eGPlQzD54PqC6+fmXzu3/NZFBo3qHO26QrWqHCSUrVKdq1ydq1KdowIic5WrspGRanWuUnmmXn0hg6uVrbu1rbOFlbOFlQsIWTqZW7k4eLs7+wW6uAe7ujmZmDuamDmamjvBkIOJmZuldWiAC4NzVLGLk6ODvb2jo6Otvb2Ti7O1nZ2ZlZWxqamRpSUImZkZm5kZmRgbGhsZmZjAkLGtswNDXWRcbURkbWh4TUhYYmJicnJyXGy0m3+kY2JlhZFFq4a2uampkZGhrauvW2CUq7OTo5OTm5ubo4ODo5sHQ09zU1dzMwS1QUFrY3NrY3tPX139xJra+vr6urq6huaW5tb2JhhoaW4uq6oEAEtyjv2kWTekAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc809a28444053c843f3c23842104294/e4706/Blockly_10.avif 230w", "/en/static/cc809a28444053c843f3c23842104294/d1af7/Blockly_10.avif 460w", "/en/static/cc809a28444053c843f3c23842104294/b6582/Blockly_10.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/cc809a28444053c843f3c23842104294/a0b58/Blockly_10.webp 230w", "/en/static/cc809a28444053c843f3c23842104294/bc10c/Blockly_10.webp 460w", "/en/static/cc809a28444053c843f3c23842104294/87ca7/Blockly_10.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/cc809a28444053c843f3c23842104294/81c8e/Blockly_10.png 230w", "/en/static/cc809a28444053c843f3c23842104294/08a84/Blockly_10.png 460w", "/en/static/cc809a28444053c843f3c23842104294/e9beb/Blockly_10.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/cc809a28444053c843f3c23842104294/e9beb/Blockly_10.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Going back to our Blockly script, clicking on the Object for the Trigger block will open the lists of objects registered with ioBroker - we can find the message we just send under `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`mqtt.0/iobroker/alarmserver/triggered`}</code>{`. Select `}<strong parentName="p">{`triggered`}</strong>{` for our trigger object:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/aa9e1edea15b9edd5819855427f57598/e9beb/Blockly_11.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "31.73913043478261%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAAA5ElEQVQY04WLzUrDQBSF8/7PEKzZWdIHEAousjKShYhoS4wx6fzduXOj0KaddMZBGqlEN36cxXc4nChdLK7m8zRNkyS5mM0ux/xLHMdZlkX7ftvvttYO3vsQwsHaXb8PIXyOeO+HcXLOHaz9FufccDwSUaR4uXq6Z0xorclgWfNVxTpCAD0Fzh0AEJFznud5pGW9fn54rWoyhrp3vqlYs6buwyCaCXiuiEhESqmiKCJQbfXy2LSMiAwZUAoUnPw3f85SytNZcFbfLMvrpXhrhemUoXrDW6GENtNwwB+RSA2Xt3fFF3deOCKPbM2oAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aa9e1edea15b9edd5819855427f57598/e4706/Blockly_11.avif 230w", "/en/static/aa9e1edea15b9edd5819855427f57598/d1af7/Blockly_11.avif 460w", "/en/static/aa9e1edea15b9edd5819855427f57598/b6582/Blockly_11.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/aa9e1edea15b9edd5819855427f57598/a0b58/Blockly_11.webp 230w", "/en/static/aa9e1edea15b9edd5819855427f57598/bc10c/Blockly_11.webp 460w", "/en/static/aa9e1edea15b9edd5819855427f57598/87ca7/Blockly_11.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/aa9e1edea15b9edd5819855427f57598/81c8e/Blockly_11.png 230w", "/en/static/aa9e1edea15b9edd5819855427f57598/08a84/Blockly_11.png 460w", "/en/static/aa9e1edea15b9edd5819855427f57598/e9beb/Blockly_11.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/aa9e1edea15b9edd5819855427f57598/e9beb/Blockly_11.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Now add another block to our script - in this case a `}<strong parentName="p">{`Sendto`}</strong>{` block for the `}<a parentName="p" {...{
        "href": "/en/Advanced_User/IOBroker_on_Raspberry_Pi/IOBroker_Setup/Smartphone_Integration/"
      }}>{`Pushover Adapter`}</a>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/577813166aad37da75a9e14ebb6529d8/e9beb/Blockly_12.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.565217391304344%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACJ0lEQVQoz0XP20/TYBgG8P41xivvvfXGeC+JURKNjkQkgheYEGOICYZ4SIzRRIERwlQWZkDoYPMEGKXg6vAAm1TWbT18bbcevn490G+0hdWwEX3yXLw3v+R5ifN9d5KLn88OzVx/i68t2L0Z1J/RB7LGrXewZ9G6Mm93kU4XacfmWyUP2522exbtS69V4vjJ0x037seGJmTTq9TtkqCJNViR9UKJ2+EUVlQZQdnmlRLQqgr8wVTpzZ2f22VOgSzQiZuDw7cfjk2RS2UZMLLOg7pRU4FmcJKIdpGu6WCHq/MSUnULQU2t7zo23nWx5/oNTAThfhRFYRgqEqdpKoQQWpZY4dcmZ/Mv56iJ7HT/q2RPPPskzdUBz/G4gaNWgiAgaqrmtwKhCSE0DANZCHDga/rj96VlemZlfjCZ6hvPPJ7j6yJXqWLXaeMwDAkNuTg4wHuBYehHGFkyEHO59UKJKazlPzxKrYzN0eRyWVcqkoT39//j2SJObjWWWM82DaOFLWQJQKSo1T8ss5FemboaT8RGU72j1QfDSkenffde+G3jEAcBIWumjX2vERiHto2RIIJcjmarbH7h03T3eOLySGbgGeo8Zxw7YZ46szfzJooiz3WJ5sFB63v/H0YWkgVAU+sFppgnlxMXRl5cfP7+aQIoW1yOwkHYnu15HtG+fP8IQwhNZNbk2uavrSLzu0DRX+Lp1ckMNUGKklAFEva8KIqazabjOH8BHm7upG606HsAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/577813166aad37da75a9e14ebb6529d8/e4706/Blockly_12.avif 230w", "/en/static/577813166aad37da75a9e14ebb6529d8/d1af7/Blockly_12.avif 460w", "/en/static/577813166aad37da75a9e14ebb6529d8/b6582/Blockly_12.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/577813166aad37da75a9e14ebb6529d8/a0b58/Blockly_12.webp 230w", "/en/static/577813166aad37da75a9e14ebb6529d8/bc10c/Blockly_12.webp 460w", "/en/static/577813166aad37da75a9e14ebb6529d8/87ca7/Blockly_12.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/577813166aad37da75a9e14ebb6529d8/81c8e/Blockly_12.png 230w", "/en/static/577813166aad37da75a9e14ebb6529d8/08a84/Blockly_12.png 460w", "/en/static/577813166aad37da75a9e14ebb6529d8/e9beb/Blockly_12.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/577813166aad37da75a9e14ebb6529d8/e9beb/Blockly_12.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Add `}<strong parentName="p">{`Text Blocks`}</strong>{`:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/510dfabc9fcf22221a1a0d28dae6e3e7/e9beb/Blockly_13.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "75.65217391304347%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAIAAABr+ngCAAAACXBIWXMAAA7DAAAOwwHHb6hkAAAC3klEQVQoz3WRS2/aWBiG/cu6nJ/STdcjTSulUzVSW7Xd9KKo7YQQ2oYCKRgITkLLxUACdhyDiSGAL+ccn2Nw8BiDHVySTTUKqTKVqj56N9/i0XejVFXFGAMAXNc1h6amadhAUNWArnueN5lMHMdpS1J3Sbst2bbt+76zhPJ9z5t583nge97c8wPv3AkC7/v3YLH4FgSz2Ww6nVrWaDy2p9OpPR5PXTcIgsVi4boupehQ1zTLOsOESFpPMoG5uzv9TE9Glu04ljUajUaWZTmO47quM5mYpklMEgTBfD6nKl8qbLUiy92+MogWdt5+zXZv33Fu/YF4HpgmgtAwDIQQhBAAAJclAMD3/cvLS4orNep8XWpJnV73SZK+ux1ayYT/zIbzbf7MHCIDGb8AITw/P7+4uKAOilyDq4ui2Ov31/Op1fi7Z3TkcTJUFhsEXnXEGOPfyXy1ylYrnU4HAFA8qKRL+UZLPDk9bR2KJwfCYNBXdF2D4Ff5amyBq/GCIHfkvjJIsV82mTRTq/LNFs+JYplXlYEO4fWqP8v+Eoqr1+SWRAAEuv6pyKzRsXhhvyocVTj+mOV0VUEYIwPd5Pp+nueNx2PqfZ5JFJnUYZHhq/GDr+VOM1xiVrbXN+jkoNYaInQ2tCxiDgkZEUIwuZFt26bCTHqLZT6yTJor03tMjs592Mm8zSWje7nk/u6+UMs3G2xbaCpdvnfSUfsEYwjhD7ldb8hSG+sQQBCJxWKvNl9sRZ5nt17k4n8n1lezm/czoZfFJNM8jPOlSlsYEvN/mWfrhXJZODruacqn5Gd6I/4uEVvLbYdz9HsmLXVle3Q2xAQbmBj4avDl437Icqsjy/Jpt9tTB0ypUMrlE6W9aH0/1SjSR6x4KiOEdAgAgjfRAZjNZleyqqmKoqiK0uv3nxcTfyXeRNfCoXDkbib0ILN5L/3P/dT6o0zkdSG5mo08pENPd6MbpR1zbDn/Ov8BrEzgU5vNYfwAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/510dfabc9fcf22221a1a0d28dae6e3e7/e4706/Blockly_13.avif 230w", "/en/static/510dfabc9fcf22221a1a0d28dae6e3e7/d1af7/Blockly_13.avif 460w", "/en/static/510dfabc9fcf22221a1a0d28dae6e3e7/b6582/Blockly_13.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/510dfabc9fcf22221a1a0d28dae6e3e7/a0b58/Blockly_13.webp 230w", "/en/static/510dfabc9fcf22221a1a0d28dae6e3e7/bc10c/Blockly_13.webp 460w", "/en/static/510dfabc9fcf22221a1a0d28dae6e3e7/87ca7/Blockly_13.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/510dfabc9fcf22221a1a0d28dae6e3e7/81c8e/Blockly_13.png 230w", "/en/static/510dfabc9fcf22221a1a0d28dae6e3e7/08a84/Blockly_13.png 460w", "/en/static/510dfabc9fcf22221a1a0d28dae6e3e7/e9beb/Blockly_13.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/510dfabc9fcf22221a1a0d28dae6e3e7/e9beb/Blockly_13.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Connect them to the `}<strong parentName="p">{`Pushover Block`}</strong>{` wherever appropriate and add some text to the push notification. Note a little trick we use here:`}</p>
    <EuiSpacer mdxType="EuiSpacer" />
    <PrimaryBox mdxType="PrimaryBox" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/927870d6cabcdf04c7927c18e83b352a/e9beb/Blockly_14.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "79.13043478260869%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAQCAIAAACZeshMAAAACXBIWXMAAA7EAAAOxAGVKw4bAAACm0lEQVQoz23S2WsTQRwH8Px/vvgs+CaiWAUVwVdBQVARLbXYJtajrUWqtkJRC21qmtJkczShmz1y7Owcu5tkj9kzpdDdkRh7pPQ7L7+Xz/yG329SnuscHhwMwsCjTuj5Vr8POm2dENM0yb8ghEzTpJQCRRFFURQETdOiIGhrOKXoWpOYqklB11EjFyOA1zf6uwXbMKjnUc/1Az8MQ9f1LMuilPq+79j2QRhB00zJUBVV0kJGm4YgkxEXv+wUS/t7VQxUfi2fm17uyZAxFsdxnAwTJ8lRHDPGep6boo7jOo5r207gBRyHclwut8tVSq2GvPpkNnPtESjyjLEkTthxkmRYm6Gfso9jmZZ7MICQbG/mytVKR2yuPEwv3Jpef7mSe7OeX80rjqF0taaBdNdijPXHsGW5lCLQ3slt1up1RWp+vZtZuvN+/nZ6+vKzlVffO47e1pGkqRo1GWPEscaw57ptALJ/thqCMMT30ksT7xYm0nNXJqeeZj4Ufs1vrX3c+W1FPmNM6erncUdRc9t5SZY6UvPb/cznG5nF6+mZS89fP56ZK/ycz67NZn9g2r8Au56LOsr2Rra8V1Wk5tLNqbmrk8sPPr29/GJ3butkbKOBtQgcw9SlGOIKV9mr15AKW1v1xnrJkHBX1jyDjtgojDERKqfYtm3qUF3XRUmUZVnTtJMmJxtKjlfN4kQA7XFMqaZpQkOQJGmE46P4bLf/V7BhLaidi7Bwis92Pn0CY4Hn80rrAszzvCAIhJDRrzw8PAzDMIyGJ4qiMAwHg0EUBE2CzmNCCMdx5XK5VqvxPF+tViuVMscVS6UyVyoXCsX9fR5CaPV6VVkaw47jdLtdjDHBBGOMEAIAqCqEKoQQqRACABDCGOOeYZQajb/iWknjeo/isAAAAABJRU5ErkJggg==')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/927870d6cabcdf04c7927c18e83b352a/e4706/Blockly_14.avif 230w", "/en/static/927870d6cabcdf04c7927c18e83b352a/d1af7/Blockly_14.avif 460w", "/en/static/927870d6cabcdf04c7927c18e83b352a/b6582/Blockly_14.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/927870d6cabcdf04c7927c18e83b352a/a0b58/Blockly_14.webp 230w", "/en/static/927870d6cabcdf04c7927c18e83b352a/bc10c/Blockly_14.webp 460w", "/en/static/927870d6cabcdf04c7927c18e83b352a/87ca7/Blockly_14.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/927870d6cabcdf04c7927c18e83b352a/81c8e/Blockly_14.png 230w", "/en/static/927870d6cabcdf04c7927c18e83b352a/08a84/Blockly_14.png 460w", "/en/static/927870d6cabcdf04c7927c18e83b352a/e9beb/Blockly_14.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/927870d6cabcdf04c7927c18e83b352a/e9beb/Blockly_14.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Save the script and make sure that it is running.`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/9ee572272c9f8c70bfc11f0ab48748e6/e9beb/Blockly_18.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "32.173913043478265%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAGCAIAAABM9SnKAAAACXBIWXMAAA7EAAAOxAGVKw4bAAABeUlEQVQY0wFuAZH+AEdcbKq4xVqEqGq08G2x7Yu75azH3rLM4bTM4I3D7Xa883q98nm98nm98nq98nq98nq98oDA8X6/83eu2AAXJzRDX3dbeJPF2O7O1ebr4uTs7O7r6Ovq5+nq2Nvs2Nrv2t3u2t3u2dzt2dzt2dzu2dvt2dvs2tzPx8oApaywrbW9vsbLytzgz+biw9vYwNjUyeDd0Objx8rGv7+8wcK/wMG/wMG+wsTBx8jGxsfFyszK5NTUyrm7AOjv9f3//+Hr7D9+dj2EeDuDdziCdTqDdzyGejqJfzuHfDuGejiFeTiFeTqHez2Kfj6KfjaEd7bRzdTU1ACdxuim0veYvdpCTlxDQUVAOjo/OjtBOzw8Ojw6OkA/Pj8/PD04MjM4MjI3MTE3MTE3MTM3NC6ytLDe3dsA8fT1////3d7fQ1RiTVNYSktKTE5OUFJSSklJOUlTTTpARzIyMTY1OD1BPEBDOzw7PT4/MTMzsrW12dnXyS/W7BXiMZkAAAAASUVORK5CYII=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9ee572272c9f8c70bfc11f0ab48748e6/e4706/Blockly_18.avif 230w", "/en/static/9ee572272c9f8c70bfc11f0ab48748e6/d1af7/Blockly_18.avif 460w", "/en/static/9ee572272c9f8c70bfc11f0ab48748e6/b6582/Blockly_18.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/9ee572272c9f8c70bfc11f0ab48748e6/a0b58/Blockly_18.webp 230w", "/en/static/9ee572272c9f8c70bfc11f0ab48748e6/bc10c/Blockly_18.webp 460w", "/en/static/9ee572272c9f8c70bfc11f0ab48748e6/87ca7/Blockly_18.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/9ee572272c9f8c70bfc11f0ab48748e6/81c8e/Blockly_18.png 230w", "/en/static/9ee572272c9f8c70bfc11f0ab48748e6/08a84/Blockly_18.png 460w", "/en/static/9ee572272c9f8c70bfc11f0ab48748e6/e9beb/Blockly_18.png 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/9ee572272c9f8c70bfc11f0ab48748e6/e9beb/Blockly_18.png",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`Go back to MQTT.fx and resend the message:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ce1509467421a0ea8f188ef4220ccdf9/1a144/Blockly_19.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "29.565217391304348%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAGABQDASIAAhEBAxEB/8QAFwABAAMAAAAAAAAAAAAAAAAAAAECBf/EABQBAQAAAAAAAAAAAAAAAAAAAAD/2gAMAwEAAhADEAAAAdywQD//xAAVEAEBAAAAAAAAAAAAAAAAAAABEP/aAAgBAQABBQIv/8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAwEBPwE//8QAFBEBAAAAAAAAAAAAAAAAAAAAEP/aAAgBAgEBPwE//8QAFBABAAAAAAAAAAAAAAAAAAAAEP/aAAgBAQAGPwJ//8QAGBABAAMBAAAAAAAAAAAAAAAAAQAQESH/2gAIAQEAAT8hWtBnCf/aAAwDAQACAAMAAAAQc8//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAEDAQE/ED//xAAUEQEAAAAAAAAAAAAAAAAAAAAQ/9oACAECAQE/ED//xAAYEAEBAQEBAAAAAAAAAAAAAAABEQBhgf/aAAgBAQABPxAFJE7vcAhA3//Z')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce1509467421a0ea8f188ef4220ccdf9/e4706/Blockly_19.avif 230w", "/en/static/ce1509467421a0ea8f188ef4220ccdf9/d1af7/Blockly_19.avif 460w", "/en/static/ce1509467421a0ea8f188ef4220ccdf9/b6582/Blockly_19.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ce1509467421a0ea8f188ef4220ccdf9/a0b58/Blockly_19.webp 230w", "/en/static/ce1509467421a0ea8f188ef4220ccdf9/bc10c/Blockly_19.webp 460w", "/en/static/ce1509467421a0ea8f188ef4220ccdf9/87ca7/Blockly_19.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ce1509467421a0ea8f188ef4220ccdf9/e83b4/Blockly_19.jpg 230w", "/en/static/ce1509467421a0ea8f188ef4220ccdf9/e41a8/Blockly_19.jpg 460w", "/en/static/ce1509467421a0ea8f188ef4220ccdf9/1a144/Blockly_19.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ce1509467421a0ea8f188ef4220ccdf9/1a144/Blockly_19.jpg",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <p>{`The push notification will be triggered and arrive at your smartphone seconds afterwards:`}</p>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "730px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ebd58fb77047a26d78e8dc3946b38e74/1a144/Blockly_20.jpg",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "49.130434782608695%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAKABQDASIAAhEBAxEB/8QAFwABAQEBAAAAAAAAAAAAAAAAAAQBBf/EABUBAQEAAAAAAAAAAAAAAAAAAAAC/9oADAMBAAIQAxAAAAHp7UqJlI//xAAYEAADAQEAAAAAAAAAAAAAAAAAAhMDIP/aAAgBAQABBQKSEsyKcf/EABcRAAMBAAAAAAAAAAAAAAAAAAACElH/2gAIAQMBAT8BhcIXD//EABcRAAMBAAAAAAAAAAAAAAAAAAACElH/2gAIAQIBAT8BptKY/8QAFBABAAAAAAAAAAAAAAAAAAAAIP/aAAgBAQAGPwJf/8QAHBAAAgEFAQAAAAAAAAAAAAAAAAGREBEhMWFx/9oACAEBAAE/Ic9mnJ7yc3NVo//aAAwDAQACAAMAAAAQhB//xAAVEQEBAAAAAAAAAAAAAAAAAAAAYf/aAAgBAwEBPxCBA//EABYRAQEBAAAAAAAAAAAAAAAAAABhAf/aAAgBAgEBPxCxbX//xAAcEAACAgIDAAAAAAAAAAAAAAAAARExIUFxgZH/2gAIAQEAAT8Q0zi5CdS9RNWG9zbE7KOD/9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ebd58fb77047a26d78e8dc3946b38e74/e4706/Blockly_20.avif 230w", "/en/static/ebd58fb77047a26d78e8dc3946b38e74/d1af7/Blockly_20.avif 460w", "/en/static/ebd58fb77047a26d78e8dc3946b38e74/b6582/Blockly_20.avif 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ebd58fb77047a26d78e8dc3946b38e74/a0b58/Blockly_20.webp 230w", "/en/static/ebd58fb77047a26d78e8dc3946b38e74/bc10c/Blockly_20.webp 460w", "/en/static/ebd58fb77047a26d78e8dc3946b38e74/87ca7/Blockly_20.webp 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ebd58fb77047a26d78e8dc3946b38e74/e83b4/Blockly_20.jpg 230w", "/en/static/ebd58fb77047a26d78e8dc3946b38e74/e41a8/Blockly_20.jpg 460w", "/en/static/ebd58fb77047a26d78e8dc3946b38e74/1a144/Blockly_20.jpg 730w"],
              "sizes": "(max-width: 730px) 100vw, 730px",
              "type": "image/jpeg"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ebd58fb77047a26d78e8dc3946b38e74/1a144/Blockly_20.jpg",
              "alt": "ioBroker Blockly Script Engine",
              "title": "ioBroker Blockly Script Engine",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      